import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthGuard implements CanActivate {
  constructor( private router: Router ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      /**
       * Generic guard for all the protected routes in the application
       * 
       * Checks if user details are present in session storage
       */
      if (
        sessionStorage.getItem('clientRef') &&
        sessionStorage.getItem('postCode')
      ) {
        return true; // Alows user to access the particular route
      }
  
      this.router.navigate(['/']); // Else user will be navigated to Login Screen
      return false;
  }
  
}
