import { Component,  OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NgxCaptchaModule, ReCaptcha2Component } from 'ngx-captcha';

import { LoginService } from '../../services/login.service';

import { environment } from '../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  
  @ViewChild('captchaElem')
  captchaElem!: ReCaptcha2Component;

  title: string = "Welcome to your Gallagher Customer Portal";
  loginForm = this.formBuilder.group({
    clientRef: [
      '',
      [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9]*$'),
        Validators.maxLength(16),
      ]
    ],
    postCode: [
      '', {
      validators: [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9 ]*$'),
        Validators.maxLength(10),
        this.checkEmptyField()
      ]
    }],
     recaptcha: ['', Validators.required]
  });
  
  //localhost
  //siteKey: string = '6LeqA4AjAAAAAH70O_nJWTR9-1VnvVzNGF6yqfTj'; 

  // https://ggbuk-retailpaymentportal-web-dev-6rbocekaik5iy.azurewebsites.net/
  //siteKey: string = '6LfHi-YjAAAAAGK6Qlu_2O5aOrBweEzETQ1dOyvu'; 

  // https://dev-customerportal.ajg.com/
  //siteKey: string = '6Lcpd4ckAAAAADbKTAqCzauCn_EugECwcbFrvw4J'; 
  
  siteKey: string = environment.recaptchaKey;

  displayClientRefTooltip: boolean = false;
  displayPostcodeTooltip: boolean = false;

  authFailed: boolean = false;

  isLoader: boolean = false;

  constructor (
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private titleService:Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  /**
   * Checks if the postcode field only has spaces
   * @returns an error or null based on the value of the postcode form field
   */
  checkEmptyField(): ValidatorFn {
    return (control:AbstractControl) : ValidationErrors | null => {
      const value = control.value;
      if (!value) return null;
      return value.trim() === '' ? { incorrectSpacePattern: true } : null;
    }
  }

  /**
   * returns the form controls if present
   * else returns null
   */
  get getFormControls() {
    return this.loginForm ? this.loginForm.controls : null;
  }

  /**
   * Called when user clicks on the Submit button
   */
  authenticateUser() {
    this.isLoader = true;
    let clientRef: string = this.loginForm.controls.clientRef.value ? this.loginForm.controls.clientRef.value : '';
    let postCode: any = this.loginForm.controls.postCode.value;
    // Removes spaces in the postcode for API call payload and converts it to uppercase
    postCode = postCode.replace(/\s/g, '').toUpperCase();

    let payload = { clientRef, postCode };
    this.loginService.authenticateUser(payload).subscribe(res => {
      //Executed when API returns success response
      this.authFailed = false;
      sessionStorage.setItem("clientRef", clientRef);
      sessionStorage.setItem("postCode", postCode.toString());
       
      this.router.navigate(['invoices']);
    }, err => {
      //Executed when API returns Error or API call fails
      this.authFailed = true;
      this.isLoader = false;
      console.error('err: ', err);
    });

    // Resets the reCAPTCHA on every button click
    this.captchaElem.resetCaptcha();
  }

}
