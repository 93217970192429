<div class="help-page-wrapper">
    <div class="container">
        <div class="backButton" (click)="goToPreviousPage()">
            <span>
                <img class="backImg" src="../assets/images/arrow-blue.svg" />
                Go back
            </span>
        </div>
        <div class="row title-wrapper">
            <div class="col-xs-12 offset-lg-1 col-lg-10">
            <div class="title-wrapper">
            <app-page-title [title]="title"></app-page-title>
            <div class="description">
                For enquiries or support, please contact your Gallagher representative using the contact details on your Gallagher documentation.
            </div>
        </div>
        <div class="faq">
            <div class="col-xs-12 offset-lg-1 col-lg-10">
                <div class="faq-heading">
                    Frequently Asked Questions
                </div>
                <div class="faq-subheading">
                    Have any questions? Below we’ve shared some answers to common questions to help you out.
                </div>    
            </div>
            <div class=" col-xs-12 offset-lg-1 col-lg-8 ">
                <div class="que-ans" *ngFor="let faq  of faqs ">
                    <div  class= "faq-questions" (click)="toggle(faq)" >
                        <mat-icon aria-hidden="false" class="toggle-btn">{{ faq.isExpanded ? 'remove' : 'add'}} </mat-icon>
                        <span class="question" [ngClass]="faq.isExpanded ? 'active' : ''">{{faq.question}}</span> 
                    </div>  
                    <div class= "faq-answers" *ngIf=" faq.isExpanded">
                        {{faq.answer}}
                    </div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </div>
</div>