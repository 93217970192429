<div class="brand-page-wrapper">
    <div class="container">
        <div class="row title-wrapper">
          <div class="col-xs-12 offset-lg-1 col-lg-10">
            <div class="title-wrapper">
                <app-page-title [title]="title"></app-page-title>
            </div>
          </div>
        </div>
    </div>
        <div class="logos-wrapper">
            <div  class="brand-logos" *ngFor="let item of brandImgs">
                <img class="images" [src]="item.path">
                <div class="img-name">{{item.name}}</div>
            </div>
        </div> 
</div>