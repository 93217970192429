import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor( private router: Router, private loginService: LoginService ) { }

  ngOnInit(): void {}

  /**
   * Called on click of Gallagher logo
   * 
   * Redirect to user to login page is user session is not valid
   * Redirect to user to invoices page is user session is valid 
   */
  navigateToMainPage() {
    if (this.isUserLoggedIn()) {
      this.router.navigate(['/invoices']);
    } else {
      this.router.navigate(['/']);
    }
  }

  /**
   * Check if the session storage has user details
   * @returns boolean
   */
  isUserLoggedIn() {
    return !!(sessionStorage.getItem('clientRef') && sessionStorage.getItem('postCode'));
  }

  /**
   * Called when user clicks Exit link in the navigation menu
   */
  signOut() {    
    this.loginService.signOut();
  }
}
