import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SuccessGuard implements CanActivate {

  constructor( private router: Router ) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    /* Check if session storage has user details and payment session ID */
    if (
      sessionStorage.getItem('clientRef') &&
      sessionStorage.getItem('postCode') &&
      sessionStorage.getItem('sessionId')
    ) {
      return true; // Allow user to navigate to 'thank you' screen (SuccessComponent)
    }

    /* If payment session ID, user will be redirected to invoices screen */
    this.router.navigate(['/invoices']);
    return false;
  }
  
}
