import { EventEmitter, Injectable } from '@angular/core';
import { LoginService } from './login.service';
@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {
  private sessionTimeout  = 10*60*1000; // 10 min 
  private timer : any;
 sessionTimeoutEvent = new EventEmitter<void>();

  constructor( private service:LoginService) { 
    this.resetTimer();
    window.addEventListener('click',() => this.resetTimer());
    window.addEventListener('mousemove',() => this.resetTimer());
    window.addEventListener('keydown',() => this.resetTimer());
  }

  /**
   * Check if the session storage has user details
   * @returns boolean
   */
  isUserLoggedIn() {
    return !!(sessionStorage.getItem('clientRef') && sessionStorage.getItem('postCode'));
  }

  /**
   * Reset the timer after ideal time is over
   */
  resetTimer(){
    this.stopTimer();
    if(this.isUserLoggedIn()){  
    this.timer = setTimeout(() => {
      this.sessionTimeoutEvent.emit();
      this.service.signOut();
    },this.sessionTimeout);
    }   
  }

  /**
   * Stop ideal time timer
   */
  stopTimer(){
    clearTimeout(this.timer);
  }
}
