import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { of } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  apiURL: string = environment.apiURL;

  constructor(private http: HttpClient) { }

  /**
   * Initiates the payment of selected invoices
   * @param body: list of invoices to be paid
   * @returns response or error received by the API call
   */
  payInvoices(body: any) {
    /*
    POC environment
    const url =
      'https://ggbuk-apim-retailpaymentportal-dev.azure-api.net/ggbuk-retailpaymentportal-func-dev-6rbocekaik5iy/PaymentFunction';
    'Ocp-Apim-Subscription-Key': '4d00d010d3e841be88590196bceb4798'
    */

    // localhost
    // return this.http.post('/api/payInvoices', body);

    const url = this.apiURL + '/api/payInvoices';
    return this.http.post(url, body);
  }

  /**
   * Gets the transaction ID of the completed payment
   * @returns response or error received by the API call
   */
  getPaymentDetails() {
    let sessionId = sessionStorage.getItem("sessionId");
    let queryParams = new HttpParams();
    queryParams = queryParams.append("SessionId", "" + sessionId);

    // localhost
    // return this.http.get('/api/getPaymentDetails', { params: queryParams });

    const url = this.apiURL + '/api/getPaymentDetails';
    return this.http.get(url, { params: queryParams });
  }
}
