import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  layoutClass: string = "";

  constructor(private router: Router) { }

  ngOnInit(): void {
    // Adds class to the wrapper div based on the navigation url
    // It is used to change the background image
    this.router.events.subscribe((event) => { 
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.layoutClass = "login-layout";
        } else {
          this.layoutClass = "main-layout";
        }
      }
    });
  }

}
