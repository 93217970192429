<div class="success-wrapper">
    <div class="container">
        <div class="row title-wrapper">
            <div class="col-xs-12 offset-lg-1 col-lg-10">
                <app-page-title [title]="title"></app-page-title>
                <div class="details">
                    <div class="email-wrapper description">
                        Your payment has been successful and a confirmation has been sent to the email address provided at payment.
                    </div>
                    <div class="id-wrapper description">
                        Your payment reference number is: <span class="font-primary-bold" *ngIf="!paymentIdError">{{paymentId}}</span>.
                    </div>
                    <div >
                    <div class="error-wrapper" *ngIf="paymentIdError">
                        <div class="title">
                            <span class="error-icon">
                                <img src="../assets/images/warning-icon.svg" alt="error-icon" />
                            </span>
                            <span>Please note: We are currently experiencing delays updating paid invoices in the portal.</span>
                        </div>
                        <div class="description">
                            <span class="error-icon">
                                <img src="../assets/images/warning-bullet.svg" alt="error-bullet" />
                            </span>
                            <span class="description-text">If you have recently paid an invoice, it may still appear in the <span *ngIf="isMobileView">list of invoices</span><span *ngIf="!isMobileView">invoice table</span>. This does not affect your invoice payment(s). Any paid invoices will be updated as soon as this is resolved.
                            </span>    
                        </div>
                        <div class="description">
                            <span class="error-icon">
                                <img class="error-bullet" src="../assets/images/warning-bullet.svg" alt="error-bullet" />
                            </span>
                            <span class="description-text">Please do not attempt to repay your invoice(s).</span>
                        </div>
                        </div> 
                    </div>
                    <div class="paymentIdErrorText description" *ngIf="paymentIdError">
                        You can now return to the customer portal or exit below.
                    </div>
                    <div class="noOutstandingCount description" *ngIf="count == 0">
                        You have no outstanding invoices left to pay.
                    </div>
                    <div class="noOutstandingCount description" *ngIf="count == 0">
                        You can now exit the portal.
                    </div>
                    <div class="text-count description" *ngIf="count > 0 && !paymentIdError">
                        You can now return to the customer portal to pay other outstanding invoices or exit below.
                </div>
                    <div  class="outstandingCount" *ngIf="count > 0 && !paymentIdError">
                        Total outstanding invoices: <span>{{count}}</span>
                    </div>
                </div>
                <button [hidden]="count == 0" mat-flat-button color="primary" (click)="redirectToInvoices()" class="return-btn">
                    Return to customer Portal
                    <img src="../assets/images/arrow-grey.svg" class="btn-icon icon-disabled" />
                    <img src="../assets/images/arrow-white.svg" class="btn-icon icon-active" />
                </button>
                <div class="signout-btn" (click)="signOut()">Exit</div>
            </div>
        </div>
    </div>
</div>