import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthGuard implements CanActivate {

  constructor( private router: Router ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    /* Check if session storage has user details */
    if (
      sessionStorage.getItem('clientRef') &&
      sessionStorage.getItem('postCode')
    ) {
      this.router.navigate(['/invoices']); // navigate to invoices screen
      return false;
    }

    return true; //if user details are not present, allow user to navigate to login screen
  }
  
}
