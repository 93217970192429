import { Component, OnInit } from '@angular/core';

//JSON file with all the brand images path
const BrandData = require('../../../assets/json/brands_img.json');

@Component({
  selector: 'app-our-brands',
  templateUrl: './our-brands.component.html',
  styleUrls: ['./our-brands.component.scss']
})
export class OurBrandsComponent implements OnInit {
  title: string = "Customer Portal Brands";
  brandImgs = BrandData;

  constructor() { }

  ngOnInit(): void {
  }

}
