import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SessionTimeoutService } from './services/session-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  //title = 'payment-portal-frontend';
  
  constructor(private titleService:Title, private session: SessionTimeoutService) {}
 
  ngOnInit() {
    // Stop/reset timer to check for ideal time
    // based on user login
   // this.titleService.setTitle(this.title);
    if(this.session.isUserLoggedIn()) {
      this.session.resetTimer();
    } else {
      this.session.stopTimer();
    }
    
  }
}
