import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  /**
   * Resuable function to remove items from session storage
   * @param sessionItems: Array of session items
   */
  removeInvoicesDetailsFromSession(sessionItems: Array<string>) {
    sessionItems.forEach(item => sessionStorage.removeItem(item));
  }
}
