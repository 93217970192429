import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { OurBrandsComponent } from './components/our-brands/our-brands.component';
import { RppHelpComponent } from './components/rpp-help/rpp-help.component';
import { SuccessComponent } from './components/success/success.component';

import { LoginAuthGuard } from './auth-guards/login.guard';
import { UserAuthGuard } from './auth-guards/user-auth.guard';
import { SuccessGuard } from './auth-guards/success.guard';

const routes: Routes = [
  { path: "", component: LoginComponent, canActivate: [ LoginAuthGuard ] },
  { path: "invoices", component: InvoicesComponent, canActivate: [ UserAuthGuard ] },
  { path: "success", component: SuccessComponent, canActivate: [ SuccessGuard ] },
  { path: "our-brands", component: OurBrandsComponent },
  { path: "help", component: RppHelpComponent },
  { path: "**", component: InvoicesComponent, canActivate: [ UserAuthGuard ] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
