import { Component, OnInit } from '@angular/core';
import { SessionTimeoutService } from 'src/app/services/session-timeout.service';
import { Router } from '@angular/router';

//JSON file with all the FAQs
const faqData = require('../../../assets/json/FAQ.json');

@Component({
  selector: 'app-rpp-help',
  templateUrl: './rpp-help.component.html',
  styleUrls: ['./rpp-help.component.scss']
})
export class RppHelpComponent implements OnInit {
  title: string = "Help";
  isExpanded: boolean = false;
  faqs = faqData;
  expandedIndex: number = -1

  constructor( 
    private sessionSevice: SessionTimeoutService, 
    private router: Router 
  ) {}

  ngOnInit(): void {}

  /**
   * To toggle accordian based on the index
   */
  toggle(index : any){
    index.isExpanded = !index.isExpanded;
  }

  /** 
   *  To go back invoice page if user  is logged in otherwise redirect to login page
   */
  goToPreviousPage(){
    if(this.sessionSevice.isUserLoggedIn()) {
        this.router.navigate(['/invoices']);
    } else {
        this.router.navigate(['/']);
    }
  }
}
