import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { of } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  apiURL: string = environment.apiURL;

  constructor( private http: HttpClient, private router: Router ) { }

  /**
   * Validates the credentials entered by the user
   * @param body: user credentials
   * @returns response or error received by the API call
   */
  authenticateUser(body: any) {
    //return of ({});
    // return this.http.post(this.loginURL, body);

    /*
    POC environment
    const url =
      'https://ggbuk-apim-retailpaymentportal-dev.azure-api.net/ggbuk-retailpaymentportal-func-dev-6rbocekaik5iy/LoginFunction';
    'Ocp-Apim-Subscription-Key': '4d00d010d3e841be88590196bceb4798'
    */

    // localhost
    //return this.http.post('/api/login', body);

    const url = this.apiURL + '/api/login';
    return this.http.post(url, body);
  }

  /**
   * Clears the session storage to logout the user
   * Navigates the user to login page
   */
  signOut() {
    sessionStorage.clear();
    this.router.navigate(['/']);
  }
}
