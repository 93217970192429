import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { InvoicesService } from '../../services/invoices.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  subscription: Subscription | undefined;
  padding: number = 0;

  isInvoicesPage: boolean = false;  

  constructor(private router: Router, private invoiceService: InvoicesService) { }

  ngOnInit(): void {
    /**
     * Keep a check on the url change
     */
    this.router.events.subscribe((event) => { 
      if (event instanceof NavigationEnd) {
        if (event.url === '/invoices') {
          this.isInvoicesPage = true;
        } else {
          this.isInvoicesPage = false;
          this.padding = 0;
        }
      }
    });

    /**
     * Created subscription to
     * fetch the height of invoices payment panel
     */
    this.subscription = this.invoiceService.getFooterBottomPadding().subscribe((padding: number) => {
      if (this.isInvoicesPage) { // Only set the padding if the current screen is invoices screen
        if (padding != this.padding) { // Only set the padding if the current padding is different from the fetched padding
          this.padding = padding;
        }
      } else {
        this.padding = 0; // Set padding to 0 if invoices is not the current screen
      }
      
    });
  }

  /**
   * Cancel subscription to
   * fetch the height of invoices payment panel
   * on component destroy
   */
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
