<div class="footer-wrapper" [ngStyle]="{'padding-bottom': padding + 'px'}">
    <div class="container">
        <div class="footer-inner-wrapper row">
            <div class="col-xs-12 col-lg-6">
                <div class="footer-navbar-wrapper">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="//policy.cookiereports.com/548e60ca-en-gb.html" class="CookieReportsLink">Cookie Policy</a>
                        </li> 
                        <li class="nav-item">
                            <a href="https://www.ajg.com/uk/brokerage-privacy-policy/" target="_blank">Privacy Notice</a>
                        </li>
                        <li class="nav-item">
                            <a href="https://www.ajg.com/uk/brokerage-legal-regulatory-information/" target="_blank">Legal & Regulatory Information</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xs-12 col-lg-6">
                <div class="copyright-wrapper">
                    <div class="copyright">© 2022 Arthur J. Gallagher & Co.</div>
                    <div class="disclosure">Arthur J. Gallagher Insurance Brokers Limited is authorised and regulated by the Financial Conduct Authority. Registered Office: Spectrum Building, 7th Floor, 55 Blythswood Street, Glasgow, G2 7AT. Registered in Scotland. Company Number: SC108909.</div> 
                </div>
            </div>
        </div>
    </div>
</div>